var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: { "head-title": _vm.$t("cip.dc.QTemplate.title") },
      }),
      _c(
        "div",
        {
          staticStyle: {
            height: "60px",
            "background-color": "#fff",
            display: "flex",
            "align-items": "center",
            padding: "0px 20px",
          },
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("cip.dc.QTemplate.field.TemplateType"))),
          ]),
          _c(
            "span",
            { staticStyle: { width: "200px" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "" },
                  on: { change: _vm.ChangeType },
                  model: {
                    value: _vm.Listtype,
                    callback: function ($$v) {
                      _vm.Listtype = $$v
                    },
                    expression: "Listtype",
                  },
                },
                _vm._l(_vm.typeList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-container",
        {
          directives: [{ name: "bodyHe", rawName: "v-bodyHe" }],
          staticClass: "new-sino-box",
        },
        [
          _c("el-main", { staticClass: "new-sino-main border-box" }, [
            _c(
              "div",
              { staticClass: "box", staticStyle: { "margin-top": "20px" } },
              _vm._l(_vm.tableData, function (item, index) {
                return _c("div", { key: index, staticClass: "box-item" }, [
                  _c(
                    "div",
                    { staticClass: "box-item-content" },
                    [
                      _c(
                        "el-card",
                        { staticClass: "box-card" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text item",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/Ai/4.png"),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _c("el-divider", {
                            attrs: { "content-position": "left" },
                          }),
                          _c("div", [_vm._v(_vm._s(item.templateName))]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.xunlianFn(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("cip.dc.QTemplate.field.testing")
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }